import BackgroundImage from 'gatsby-background-image'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: "Sora", Helvetica, sans-serif;
  }
`

export const ContentHeader = styled.section`
  align-items: center;
  display: flex;
  min-height: calc(100vh - 64px);
  position: relative;

  @media ${device.tablet} {
    min-height: 500px;
  }
  @media ${device.desktopLG} {
    min-height: calc(100vh - 735pxpx);  
  }
`

export const ScrollLink = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: auto;
`

export const OurPerformance = styled.section`
  @media ${device.tablet} {
    min-height: 302px;
  }
  @media ${device.desktopLG} {
    min-height: 350px;
  }
  @media ${device.desktopXL} {
    min-height: 456px;
  }
`

export const OurPerformanceBackground = styled(BackgroundImage)`
  width: 100%;
  height: 321px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  
  @media ${device.tablet} {
    position: absolute !important;
    width: 372px;
    height: 302px;
  }

  @media ${device.desktopLG} {
    width: 501px;
    height: 350px;
  }

  @media ${device.desktopXL} {
    width: 611px;
    height: 456px;
  }
`

export const Contact = styled.section`
  align-items: center;
  background: ${orange['100']};
  display: flex;
  min-height: 530px;
  position: relative;

  @media ${device.tablet} {
    min-height: 448px;
  }

  @media ${device.desktopLG} {
    min-height: 504px;
  }

  @media ${device.desktopXL} {
    min-height: 648px;   
  } 
`
